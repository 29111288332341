import React from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { GatsbyImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"

//Portable text components
const portableComponents = {
  block: {
    normal: ({ children }) => (
      <p className="text-love-grey mt-lead-1">{children}</p>
    ),
  },
}

const ArticleImageWithCaption = ({ value }) => {
  const sanityConfig = { projectId: "uo79gp98", dataset: "production" }
  const imageData = getGatsbyImageData(
    value.asset._ref,
    { width: 1024 },
    sanityConfig
  )
  return (
    <div className="col-span-7 col-start-2 mt-lead-4 mb-lead-4">
      <GatsbyImage alt={value.altText} image={imageData} />
      <PortableText value={value.caption} components={portableComponents} />
    </div>
  )
}

export default ArticleImageWithCaption
